import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SideNavigation, {
    SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';

function SideNavComponent({
    history,
}: RouteComponentProps): React.ReactElement<RouteComponentProps> {
    const onFollowHandler = (
        event: CustomEvent<SideNavigationProps.FollowDetail>
    ): void => {
        event.preventDefault();
        if (event.detail.href) {
            history.push(event.detail.href);
        }
    };

    const header = {
        href: '/',
        text: 'Pigeonhole',
    };

    const items: ReadonlyArray<SideNavigationProps.Item> = [
        {
            type: 'link',
            text: 'Work Categories',
            href: '/react/work-category',
        },
        {
            type: 'link',
            text: 'Subject Lines',
            href: '/react/subject-lines',
        },
    ];

    return (
        <SideNavigation
            activeHref="/"
            header={header}
            items={items}
            onFollow={onFollowHandler}
        />
    );
}

export default withRouter(SideNavComponent);
