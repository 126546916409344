import { ClientConfig } from '@amzn/csphoenix-react-client';

export const clientConfig: Record<string, ClientConfig> = {
    local: {
        tokenCookieName: 'pgw-local.token',
        redirectEndpoint: 'https://local.pgw.cs.a2z.com:3000/',
        clientStateCookieName: 'pigeonhole-local',
        clientId: 'pgw-local',
    },
    beta: {
        tokenCookieName: 'pgw-test.token',
        redirectEndpoint: 'https://beta.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-test',
        clientId: 'pgw-test',
    },
    'gamma-na': {
        tokenCookieName: 'pgw-prod.token',
        redirectEndpoint: 'https://gamma-na.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod',
    },
    'gamma-eu': {
        tokenCookieName: 'pgw-prod-eu.token',
        redirectEndpoint: 'https://gamma-eu.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod-eu',
    },
    'gamma-fe': {
        tokenCookieName: 'pgw-prod-fe.token',
        redirectEndpoint: 'https://gamma-fe.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod-fe',
    },
    'gamma-cn': {
        tokenCookieName: 'pgw-prod-cn.token',
        redirectEndpoint: 'https://gamma-cn.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod-cn',
    },
    'prod-na': {
        tokenCookieName: 'pgw-prod.token',
        redirectEndpoint: 'https://prod-na.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod',
    },
    'prod-eu': {
        tokenCookieName: 'pgw-prod-eu.token',
        redirectEndpoint: 'https://prod-eu.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod-eu',
    },
    'prod-fe': {
        tokenCookieName: 'pgw-prod-fe.token',
        redirectEndpoint: 'https://prod-fe.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod-fe',
    },
    'prod-cn': {
        tokenCookieName: 'pgw-prod-cn.token',
        redirectEndpoint: 'https://prod-cn.pigeonhole.pgw.cs.a2z.com/',
        clientStateCookieName: 'pigeonhole-prod',
        clientId: 'pgw-prod-cn',
    },
};
