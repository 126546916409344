import {
    FeatureValueLog,
    GetState,
    WorkCategoryContentTemplateAssociationLog,
    WorkCategoryLog,
} from '../../../../Interfaces';

export const Types = {
    GET_WORKCATEGORYLOGS_REQUEST: 'GET_WORKCATEGORYLOGS_REQUEST',
    GET_WORKCATEGORYLOGS_SUCCESS: 'GET_WORKCATEGORYLOGS_SUCCESS',
    GET_WORKCATEGORYLOGS_FAILURE: 'GET_WORKCATEGORYLOGS_FAILURE',

    GET_FEATUREVALUELOGS_REQUEST: 'GET_FEATUREVALUELOGS_REQUEST',
    GET_FEATUREVALUELOGS_SUCCESS: 'GET_FEATUREVALUELOGS_SUCCESS',
    GET_FEATUREVALUELOGS_FAILURE: 'GET_FEATUREVALUELOGS_FAILURE',

    GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_REQUEST:
        'GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_REQUEST',
    GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_SUCCESS:
        'GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_SUCCESS',
    GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_FAILURE:
        'GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_FAILURE',
};

/*
 * ACTION TYPES
 */
export interface GetWorkCategoryLogsRequestAction {
    type: typeof Types.GET_WORKCATEGORYLOGS_REQUEST;
}

export interface GetWorkCategoryLogsSuccessAction {
    type: typeof Types.GET_WORKCATEGORYLOGS_SUCCESS;
    data: WorkCategoryLog[];
}

export interface GetWorkCategoryLogsFailureAction {
    type: typeof Types.GET_WORKCATEGORYLOGS_FAILURE;
}

export interface GetFeatureValueLogsRequestAction {
    type: typeof Types.GET_FEATUREVALUELOGS_REQUEST;
}

export interface GetFeatureValueLogsSuccessAction {
    type: typeof Types.GET_FEATUREVALUELOGS_SUCCESS;
    data: FeatureValueLog[];
}

export interface GetFeatureValueLogsFailureAction {
    type: typeof Types.GET_FEATUREVALUELOGS_FAILURE;
}

export interface GetWorkCategoryContentTemplateAssociationLogsRequestAction {
    type: typeof Types.GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_REQUEST;
}

export interface GetWorkCategoryContentTemplateAssociationLogsSuccessAction {
    type: typeof Types.GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_SUCCESS;
    data: WorkCategoryContentTemplateAssociationLog[];
}

export interface GetWorkCategoryContentTemplateAssociationLogsFailureAction {
    type: typeof Types.GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_FAILURE;
}

export type WorkCategoryLogsActionType =
    | GetWorkCategoryLogsRequestAction
    | GetWorkCategoryLogsSuccessAction
    | GetWorkCategoryLogsFailureAction
    | GetFeatureValueLogsRequestAction
    | GetFeatureValueLogsSuccessAction
    | GetFeatureValueLogsFailureAction
    | GetWorkCategoryContentTemplateAssociationLogsRequestAction
    | GetWorkCategoryContentTemplateAssociationLogsSuccessAction
    | GetWorkCategoryContentTemplateAssociationLogsFailureAction;

/*
 *State types for Work Categories
 */
export interface WorkCategoryLogState {
    workCategoryAudit: {
        getState: GetState;
        workCategoryLogs: WorkCategoryLog[];
    };
    featureValueAudit: {
        getState: GetState;
        featureValueLogs: FeatureValueLog[];
    };
    workCategoryContentTemplateAssociationAudit: {
        getState: GetState;
        associationLogs: WorkCategoryContentTemplateAssociationLog[];
    };
}
