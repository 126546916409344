import {
    ContentTemplatesActionType,
    ContentTemplateState,
    GetContentTemplateLogsSuccessAction,
    GetSigblocksSuccessAction,
    GetSubjectLinesSuccessAction,
    Types,
} from './ContentTemplates.types';
import { GetState, PostState } from '../../../../Interfaces';

const initialState: ContentTemplateState = {
    sigblocks: {
        sigblocks: [],
        getState: GetState.INITIAL,
        postState: PostState.INITIAL,
    },
    subjectLines: {
        subjectLines: [],
        getState: GetState.INITIAL,
        postState: PostState.INITIAL,
        subjectLineWorkCategoryAssociation: {
            postState: PostState.INITIAL,
        },
    },
    contentTemplateAudit: {
        contentTemplateLogs: [],
        getState: GetState.INITIAL,
    },
};

const contentTemplateReducer = (
    prevState: ContentTemplateState = initialState,
    action: ContentTemplatesActionType
): ContentTemplateState => {
    switch (action.type) {
        case Types.GET_SIGBLOCKS_REQUEST:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_SIGBLOCKS_SUCCESS:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    sigblocks: (action as GetSigblocksSuccessAction).data,
                    getState: GetState.SUCCESS,
                },
            };

        case Types.GET_SIGBLOCKS_FAILURE:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.FAILURE,
                },
            };

        case Types.UPDATE_SIGBLOCK_REQUEST:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    postState: PostState.LOADING,
                },
            };

        case Types.UPDATE_SIGBLOCK_SUCCESS:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    postState: PostState.SUCCESS,
                },
            };

        case Types.UPDATE_SIGBLOCK_FAILURE:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    postState: PostState.FAILURE,
                },
            };

        case Types.ADD_SIGBLOCK_REQUEST:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.INITIAL,
                    postState: PostState.LOADING,
                },
            };

        case Types.ADD_SIGBLOCK_SUCCESS:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.INITIAL,
                    postState: PostState.SUCCESS,
                },
            };

        case Types.ADD_SIGBLOCK_FAILURE:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.INITIAL,
                    postState: PostState.FAILURE,
                },
            };

        case Types.REMOVE_SIGBLOCK_REQUEST:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.INITIAL,
                    postState: PostState.LOADING,
                },
            };

        case Types.REMOVE_SIGBLOCK_SUCCESS:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.INITIAL,
                    postState: PostState.SUCCESS,
                },
            };

        case Types.REMOVE_SIGBLOCK_FAILURE:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    getState: GetState.INITIAL,
                    postState: PostState.FAILURE,
                },
            };

        case Types.RESET_SIGBLOCK_POST_STATE:
            return {
                ...prevState,
                sigblocks: {
                    ...prevState.sigblocks,
                    postState: PostState.INITIAL,
                },
            };

        case Types.GET_SUBJECTLINES_BY_WORKCATEGORY_ID_REQUEST:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_SUBJECTLINES_BY_WORKCATEGORY_ID_SUCCESS:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    subjectLines: (action as GetSubjectLinesSuccessAction)
                        .payload,
                    getState: GetState.SUCCESS,
                },
            };

        case Types.GET_SUBJECTLINES_BY_WORKCATEGORY_ID_FAILURE:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    getState: GetState.FAILURE,
                },
            };

        case Types.UPDATE_SUBJECTLINE_REQUEST:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    postState: PostState.LOADING,
                },
            };

        case Types.UPDATE_SUBJECTLINE_SUCCESS:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    postState: PostState.SUCCESS,
                },
            };

        case Types.UPDATE_SUBJECTLINE_FAILURE:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    postState: PostState.FAILURE,
                },
            };

        case Types.RESET_SUBJECTLINE_POST_STATE:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    postState: PostState.INITIAL,
                },
            };

        case Types.RESET_CONTENT_TEMPLATE_STATE:
            return initialState;

        case Types.ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.LOADING,
                    },
                },
            };

        case Types.ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    getState: GetState.INITIAL,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.SUCCESS,
                    },
                },
            };

        case Types.ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.FAILURE,
                    },
                },
            };

        case Types.REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.LOADING,
                    },
                },
            };

        case Types.REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    getState: GetState.INITIAL,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.SUCCESS,
                    },
                },
            };

        case Types.REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.FAILURE,
                    },
                },
            };

        case Types.RESET_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_POST_STATE:
            return {
                ...prevState,
                subjectLines: {
                    ...prevState.subjectLines,
                    subjectLineWorkCategoryAssociation: {
                        postState: PostState.INITIAL,
                    },
                },
            };

        case Types.GET_CONTENT_TEMPLATE_LOGS_REQUEST:
            return {
                ...prevState,
                contentTemplateAudit: {
                    ...prevState.contentTemplateAudit,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_CONTENT_TEMPLATE_LOGS_SUCCESS:
            return {
                ...prevState,
                contentTemplateAudit: {
                    ...prevState.contentTemplateAudit,
                    getState: GetState.SUCCESS,
                    contentTemplateLogs: (
                        action as GetContentTemplateLogsSuccessAction
                    ).data,
                },
            };

        case Types.GET_CONTENT_TEMPLATE_LOGS_FAILURE:
            return {
                ...prevState,
                contentTemplateAudit: {
                    ...prevState.contentTemplateAudit,
                    getState: GetState.FAILURE,
                },
            };

        default:
            return prevState;
    }
};

export default contentTemplateReducer;
