import {
    FeatureValuesActionType,
    FeatureValueState,
    GetFeatureNamesAndKeysSuccessAction,
    GetFeatureValuesSuccessAction,
    SetFeatureValueIsActiveAction,
    SetFeatureValueValueAction,
    Types,
} from './FeatureValues.types';
import { GetState, PostState } from '../../../../Interfaces';
import { findFeatureValueWithId } from '../../../../Utilities';

const initialState: FeatureValueState = {
    featureValues: [],
    getState: GetState.INITIAL,
    postState: PostState.INITIAL,
    featureNamesAndKeys: {
        getState: GetState.INITIAL,
        features: [],
    },
};

const featureValueReducer = (
    prevState: FeatureValueState = initialState,
    action: FeatureValuesActionType
): FeatureValueState => {
    switch (action.type) {
        case Types.GET_FEATURE_VALUES_REQUEST:
            return {
                ...prevState,
                getState: GetState.LOADING,
            };

        case Types.GET_FEATURE_VALUES_SUCCESS:
            return {
                ...prevState,
                featureValues: (action as GetFeatureValuesSuccessAction).data,
                getState: GetState.SUCCESS,
            };

        case Types.GET_FEATURE_VALUES_FAILURE:
            return {
                ...prevState,
                getState: GetState.FAILURE,
            };

        case Types.SET_FEATURE_VALUE_IS_ACTIVE: {
            const newData = prevState.featureValues;
            const setAction = action as SetFeatureValueIsActiveAction;
            const featureValue = findFeatureValueWithId(
                setAction.featureValueId,
                prevState.featureValues
            );
            if (featureValue) {
                featureValue.isActive = setAction.isActive;
            }
            return {
                ...prevState,
                featureValues: newData,
            };
        }

        case Types.SET_FEATURE_VALUE_VALUE: {
            const newData = prevState.featureValues;
            const setAction = action as SetFeatureValueValueAction;
            const featureValue = findFeatureValueWithId(
                setAction.featureValueId,
                prevState.featureValues
            );
            if (featureValue) {
                featureValue.value = setAction.value;
            }
            return {
                ...prevState,
                featureValues: newData,
            };
        }

        case Types.UPDATE_FEATURE_VALUE_REQUEST:
            return {
                ...prevState,
                postState: PostState.LOADING,
            };

        case Types.UPDATE_FEATURE_VALUE_SUCCESS:
            return {
                ...prevState,
                postState: PostState.SUCCESS,
            };

        case Types.UPDATE_FEATURE_VALUE_FAILURE:
            return {
                ...prevState,
                postState: PostState.FAILURE,
            };

        case Types.RESET_FEATURE_VALUE_POST_STATE:
            return {
                ...prevState,
                postState: PostState.INITIAL,
            };

        case Types.RESET_FEATURE_VALUES_STATE:
            return initialState;

        case Types.GET_FEATURE_NAMES_AND_KEYS_REQUEST:
            return {
                ...prevState,
                featureNamesAndKeys: {
                    getState: GetState.LOADING,
                    features: [],
                },
            };

        case Types.GET_FEATURE_NAMES_AND_KEYS_SUCCESS:
            return {
                ...prevState,
                featureNamesAndKeys: {
                    getState: GetState.SUCCESS,
                    features: (action as GetFeatureNamesAndKeysSuccessAction)
                        .data,
                },
            };

        case Types.GET_FEATURE_NAMES_AND_KEYS_FAILURE:
            return {
                ...prevState,
                featureNamesAndKeys: {
                    getState: GetState.FAILURE,
                    features: [],
                },
            };

        case Types.RESET_FEATURE_NAMES_AND_KEYS_STATE:
            return {
                ...prevState,
                featureNamesAndKeys: initialState.featureNamesAndKeys,
            };

        default:
            return prevState;
    }
};

export default featureValueReducer;
