import {
    GetSurveysBaseInfoSuccessAction,
    GetWorkCategoriesForHMDSuccessAction,
    SurveyActionType,
    SurveyState,
    Types,
} from './Surveys.types';
import { GetState } from '../../../../../Interfaces';

const initialState: SurveyState = {
    getState: GetState.INITIAL,
    surveys: [],
    associatedWorkCategories: {
        getState: GetState.INITIAL,
        workCategories: [],
    },
};

export const SurveyReducer = (
    prevState: SurveyState = initialState,
    action: SurveyActionType
) => {
    switch (action.type) {
        case Types.GET_SURVEYS_BASE_INFO_REQUEST:
            return {
                ...prevState,
                getState: GetState.LOADING,
            };

        case Types.GET_SURVEYS_BASE_INFO_SUCCESS:
            return {
                ...prevState,
                surveys: (action as GetSurveysBaseInfoSuccessAction).payload,
                getState: GetState.SUCCESS,
            };

        case Types.GET_SURVEYS_BASE_INFO_FAILURE:
            return {
                ...prevState,
                getState: GetState.FAILURE,
            };

        case Types.GET_WORK_CATEGORIES_FOR_HMD_REQUEST:
            return {
                ...prevState,
                associatedWorkCategories: {
                    ...prevState.associatedWorkCategories,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_WORK_CATEGORIES_FOR_HMD_SUCCESS:
            return {
                ...prevState,
                associatedWorkCategories: {
                    workCategories: (
                        action as GetWorkCategoriesForHMDSuccessAction
                    ).payload,
                    getState: GetState.SUCCESS,
                },
            };

        case Types.GET_WORK_CATEGORIES_FOR_HMD_FAILURE:
            return {
                ...prevState,
                associatedWorkCategories: {
                    ...prevState.associatedWorkCategories,
                    getState: GetState.FAILURE,
                },
            };

        case Types.RESET_ASSOCIATED_WORK_CATEGORIES_STATE:
            return {
                ...prevState,
                associatedWorkCategories: initialState.associatedWorkCategories,
            };

        default:
            return prevState;
    }
};

export default SurveyReducer;
