import { GetState, PostState, WorkCategory } from '../../../Interfaces';

export const WorkCategoryRequestType = {
    GET_WORK_CATEGORIES_REQUEST: 'GET_WORK_CATEGORIES_REQUEST',
    GET_WORK_CATEGORIES_SUCCESS: 'GET_WORK_CATEGORIES_SUCCESS',
    GET_WORK_CATEGORIES_FAILURE: 'GET_WORK_CATEGORIES_FAILURE',

    SAVE_WORK_CATEGORY_REQUEST: 'SAVE_WORK_CATEGORY_REQUEST',
    SAVE_WORK_CATEGORY_SUCCESS: 'SAVE_WORK_CATEGORY_SUCCESS',
    SAVE_WORK_CATEGORY_FAILURE: 'SAVE_WORK_CATEGORY_FAILURE',
    RESET_WORK_CATEGORY_POST_STATE: 'RESET_FEATURE_VALUE_POST_STATE',
};

/*
 * ACTION TYPES
 */
export interface GetWorkCategoriesRequestAction {
    type: typeof WorkCategoryRequestType.GET_WORK_CATEGORIES_REQUEST;
}

export interface GetWorkCategoriesSuccessAction {
    type: typeof WorkCategoryRequestType.GET_WORK_CATEGORIES_SUCCESS;
    data: WorkCategory[];
}

export interface GetWorkCategoriesFailureAction {
    type: typeof WorkCategoryRequestType.GET_WORK_CATEGORIES_FAILURE;
}

export interface SaveWorkCategoryRequestAction {
    type: typeof WorkCategoryRequestType.SAVE_WORK_CATEGORY_REQUEST;
}

export interface SaveWorkCategorySuccessAction {
    type: typeof WorkCategoryRequestType.SAVE_WORK_CATEGORY_SUCCESS;
}

export interface SaveWorkCategoryFailureAction {
    type: typeof WorkCategoryRequestType.SAVE_WORK_CATEGORY_FAILURE;
}

export interface ResetWorkCategoryPostStateAction {
    type: typeof WorkCategoryRequestType.RESET_WORK_CATEGORY_POST_STATE;
}

export type WorkCategoryActionType =
    | GetWorkCategoriesRequestAction
    | GetWorkCategoriesSuccessAction
    | GetWorkCategoriesFailureAction
    | SaveWorkCategoryRequestAction
    | SaveWorkCategorySuccessAction
    | SaveWorkCategoryFailureAction
    | ResetWorkCategoryPostStateAction;

/*
 *State types for Work Categories
 */
export interface WorkCategoryState {
    workCategories: WorkCategory[];
    queryState: GetState;
    postState: PostState;
}
