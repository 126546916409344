import {
    GetWorkCategoriesSuccessAction,
    WorkCategoryActionType,
    WorkCategoryRequestType,
    WorkCategoryState,
} from './WorkCategory.types';
import { GetState, PostState } from '../../../Interfaces';

const initialState: WorkCategoryState = {
    workCategories: [],
    queryState: GetState.INITIAL,
    postState: PostState.INITIAL,
};

const workCategoryReducer = (
    prevState: WorkCategoryState = initialState,
    action: WorkCategoryActionType
): WorkCategoryState => {
    switch (action.type) {
        case WorkCategoryRequestType.SAVE_WORK_CATEGORY_REQUEST:
            return {
                ...prevState,
                postState: PostState.LOADING,
            };

        case WorkCategoryRequestType.SAVE_WORK_CATEGORY_SUCCESS:
            return {
                ...prevState,
                postState: PostState.SUCCESS,
            };

        case WorkCategoryRequestType.SAVE_WORK_CATEGORY_FAILURE:
            return {
                ...prevState,
                postState: PostState.FAILURE,
            };

        case WorkCategoryRequestType.RESET_WORK_CATEGORY_POST_STATE:
            return {
                ...prevState,
                postState: PostState.INITIAL,
            };

        case WorkCategoryRequestType.GET_WORK_CATEGORIES_REQUEST:
            return {
                ...prevState,
                queryState: GetState.LOADING,
            };

        case WorkCategoryRequestType.GET_WORK_CATEGORIES_SUCCESS:
            return {
                ...prevState,
                workCategories: (action as GetWorkCategoriesSuccessAction).data,
                queryState: GetState.SUCCESS,
            };

        case WorkCategoryRequestType.GET_WORK_CATEGORIES_FAILURE:
            return {
                ...prevState,
                queryState: GetState.FAILURE,
            };

        default:
            return prevState;
    }
};

export default workCategoryReducer;
