import {
    GetSubjectLinesSuccessAction,
    SubjectLineActionType,
    SubjectLinesRequestType,
    SubjectLineState,
} from './SubjectLines.types';
import { GetState, PostState } from '../../../Interfaces';

const initialState: SubjectLineState = {
    subjectLines: [],
    queryState: GetState.INITIAL,
    postState: PostState.INITIAL,
};

const subjectLineReducer = (
    prevState: SubjectLineState = initialState,
    action: SubjectLineActionType
): SubjectLineState => {
    switch (action.type) {
        case SubjectLinesRequestType.GET_SUBJECT_LINES_REQUEST:
            return {
                ...prevState,
                queryState: GetState.LOADING,
            };

        case SubjectLinesRequestType.GET_SUBJECT_LINES_SUCCESS:
            return {
                ...prevState,
                subjectLines: (action as GetSubjectLinesSuccessAction).data,
                queryState: GetState.SUCCESS,
            };

        case SubjectLinesRequestType.GET_SUBJECT_LINES_FAILURE:
            return {
                ...prevState,
                queryState: GetState.FAILURE,
            };

        case SubjectLinesRequestType.ADD_SUBJECT_LINE_REQUEST:
            return {
                ...prevState,
                postState: PostState.LOADING,
            };

        case SubjectLinesRequestType.ADD_SUBJECT_LINE_SUCCESS:
            return {
                ...prevState,
                postState: PostState.SUCCESS,
            };

        case SubjectLinesRequestType.ADD_SUBJECT_LINE_FAILURE:
            return {
                ...prevState,
                postState: PostState.FAILURE,
            };

        case SubjectLinesRequestType.RESET_SUBJECT_LINE_POST_STATE:
            return {
                ...prevState,
                postState: PostState.INITIAL,
            };

        default:
            return prevState;
    }
};

export default subjectLineReducer;
