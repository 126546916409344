import {
    DartActionType,
    DartState,
    GetDartConfigurationsSuccessAction,
    Types,
} from './Dart.types';
import { GetState, PostState } from '../../../../../Interfaces';

const initialState: DartState = {
    getState: GetState.INITIAL,
    postState: PostState.INITIAL,
    dartConfigs: [],
};

export const DartReducer = (
    prevState: DartState = initialState,
    action: DartActionType
) => {
    switch (action.type) {
        case Types.GET_DART_CONFIGURATIONS_REQUEST:
            return {
                ...prevState,
                getState: GetState.LOADING,
            };

        case Types.GET_DART_CONFIGURATIONS_SUCCESS:
            return {
                ...prevState,
                dartConfigs: (action as GetDartConfigurationsSuccessAction)
                    .payload,
                getState: GetState.SUCCESS,
            };

        case Types.GET_DART_CONFIGURATIONS_FAILURE:
            return {
                ...prevState,
                getState: GetState.FAILURE,
            };

        case Types.ADD_DART_CONFIGURATIONS_REQUEST:
            return {
                ...prevState,
                postState: GetState.LOADING,
            };

        case Types.ADD_DART_CONFIGURATIONS_SUCCESS:
            return {
                ...prevState,
                postState: GetState.SUCCESS,
            };

        case Types.ADD_DART_CONFIGURATIONS_FAILURE:
            return {
                ...prevState,
                postState: GetState.FAILURE,
            };

        case Types.UPDATE_DART_CONFIGURATIONS_REQUEST:
            return {
                ...prevState,
                postState: GetState.LOADING,
            };

        case Types.UPDATE_DART_CONFIGURATIONS_SUCCESS:
            return {
                ...prevState,
                postState: GetState.SUCCESS,
            };

        case Types.UPDATE_DART_CONFIGURATIONS_FAILURE:
            return {
                ...prevState,
                postState: GetState.FAILURE,
            };

        default:
            return prevState;
    }
};

export default DartReducer;
