// Combined reducers will go here
import { combineReducers } from 'redux';
import WorkCategoryReducer from '../WorkCategory/store/WorkCategory.reducers';
import FeatureValueReducer from '../WorkCategoryDetails/FeatureValues/store/FeatureValues.reducers';
import ContentTemplateReducer from '../WorkCategoryDetails/ContentTemplates/store/ContentTemplates.reducers';
import SurveyReducer from '../WorkCategoryDetails/FeatureValues/HMD/store/Surveys.reducers';
import AuditHistoryReducer from '../WorkCategoryDetails/AuditHistory/store/AuditHistory.reducers';
import SubjectLinesReducer from '../SubjectLines/store/SubjectLines.reducers';
import DartReducer from '../WorkCategoryDetails/FeatureValues/Dart/store/Dart.reducers';

const RootReducer = combineReducers({
    workCategory: WorkCategoryReducer,
    featureValue: FeatureValueReducer,
    contentTemplate: ContentTemplateReducer,
    survey: SurveyReducer,
    auditHistory: AuditHistoryReducer,
    subjectLines: SubjectLinesReducer,
    dartConfigs: DartReducer,
});

export type AppState = ReturnType<typeof RootReducer>;

export default RootReducer;
