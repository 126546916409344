import { getPhoenixQualifier } from '../Utilities';
import PhoenixAPI from '@amzn/csphoenix-react-client';
import { clientConfig } from './ClientConfig';

const phoenixAPI: PhoenixAPI = new PhoenixAPI(
    getPhoenixQualifier(window.location),
    'en_US',
    clientConfig
);

export function getPhoenixAPI(): PhoenixAPI {
    return phoenixAPI;
}

export function authenticate(setFailureMethod: (e: string) => void): void {
    getPhoenixAPI()
        .testAuthCall()
        .then(
            (r) => {
                if (r.status === 401) {
                    setFailureMethod('Authenticating');
                    getPhoenixAPI().startAuth((e) => {
                        setFailureMethod(e);
                    });
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
        );
}
