import PropTypes from 'prop-types';
import React from 'react';

const LoadingComponent = ({ error, pastDelay }) => {
    if (error) {
        console.error(error);
        return <div>Error!</div>;
    } else if (pastDelay) {
        return <div>Loading...</div>;
    }
    return null;
};

LoadingComponent.propTypes = {
    error: PropTypes.string,
    pastDelay: PropTypes.bool,
};

export default LoadingComponent;
