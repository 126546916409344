import React from 'react';
import {
    ContentTemplate,
    DartConfiguration,
    FeatureValue,
    LogTableEntry,
    WorkCategory,
} from './Interfaces';
import {
    activeDisplayComponent,
    featureValueCellContent,
    linkSubjectLine,
    linkWorkCategory,
    openChangeDetails,
} from './Utilities';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Icon from '@amzn/awsui-components-react/polaris/icon';

export const WC_COLUMN_DEFINITIONS = [
    {
        id: 'number',
        sortingField: 'id',
        header: 'ID',
        cell: (item: WorkCategory) => item.id,
    },
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: (item: WorkCategory) => linkWorkCategory(item),
        minWidth: 150,
    },
    {
        id: 'marketplace_id',
        sortingField: 'marketplaceId',
        header: 'Marketplace ID',
        cell: (item: WorkCategory) => item.marketplaceId,
    },
    {
        id: 'description',
        header: 'Description',
        cell: (item: WorkCategory) => item.description,
        minWidth: 150,
    },
    {
        id: 'is_active',
        header: 'Status',
        cell: (item: WorkCategory) => activeDisplayComponent(item.isActive),
    },
    {
        id: 'last_updated_date',
        sortingField: 'lastUpdatedDate',
        header: 'Last Updated Date',
        cell: (item: WorkCategory) => item.lastUpdatedDate,
    },
    {
        id: 'last_updated_by',
        sortingField: 'lastUpdatedBy',
        header: 'Last Updated By',
        cell: (item: WorkCategory) => item.lastUpdatedBy,
    },
    {
        id: 'date_created',
        sortingField: 'creationDate',
        header: 'Date Created',
        cell: (item: WorkCategory) => item.creationDate,
    },
];

export const SL_COLUMN_DEFINITIONS = (canEdit = false, workCategoryId = -1) => [
    {
        id: 'number',
        sortingField: 'id',
        header: 'ID',
        cell: (item: ContentTemplate) => item.id,
    },
    {
        id: 'comm_type_code',
        sortingField: 'commTypeCode',
        header: 'Contact Type',
        cell: (item: ContentTemplate) => CommTypeMap.get(item.commTypeCode),
    },
    {
        id: 'locale',
        sortingField: 'locale',
        header: 'Locale',
        cell: (item: ContentTemplate) => item.locale,
    },
    {
        id: 'text',
        header: 'Subject Line Text',
        cell: (item: ContentTemplate) =>
            canEdit ? linkSubjectLine(item, workCategoryId) : item.text,
    },
    {
        id: 'last_edited_by',
        sortingField: 'lastEditedBy',
        header: 'Last Edited By',
        cell: (item: ContentTemplate) => item.lastEditedBy,
    },
    {
        id: 'last_edited_date',
        sortingField: 'lastEditedDate',
        header: 'Last Edited Date',
        cell: (item: ContentTemplate) => item.lastEditedDate,
    },
    {
        id: 'is_active',
        header: 'Status',
        cell: (item: ContentTemplate) => activeDisplayComponent(item.isActive),
    },
];

export function AUDIT_COLUMN_DEFINITIONS(
    auditItems: LogTableEntry[],
    addSelectedItems: (id: string) => void,
    removeSelectedItems: (id: string) => void,
    sortingAscending: boolean
): TableProps.ColumnDefinition<LogTableEntry>[] {
    return [
        {
            id: 'changeType',
            header: 'Type of change',
            minWidth: 450,
            cell: (item: LogTableEntry) => {
                if (!item.expandable) {
                    // Currently, the only items which are not expandable are work category subject line association logs
                    return (
                        <SpaceBetween size="xs" direction="horizontal">
                            <Icon
                                name="caret-right-filled"
                                size="normal"
                                variant="disabled"
                            />
                            <div>{item.changeType}</div>
                        </SpaceBetween>
                    );
                } else if (!item.parentLogId) {
                    return openChangeDetails(
                        item,
                        auditItems,
                        addSelectedItems,
                        removeSelectedItems
                    );
                } else {
                    return (
                        <div style={{ paddingLeft: '10px' }}>
                            {item.changeType}
                        </div>
                    );
                }
            },
        },
        {
            id: 'previousValue',
            header: 'Previous Value',
            width: 150,
            cell: (item: LogTableEntry) => {
                if (
                    item.changeType === 'Active Status' &&
                    typeof item.previousValue === 'boolean'
                ) {
                    return activeDisplayComponent(item.previousValue);
                } else {
                    return item.previousValue ? item.previousValue : '-';
                }
            },
        },
        {
            id: 'newValue',
            header: 'New Value',
            width: 150,
            cell: (item: LogTableEntry) => {
                if (
                    item.changeType === 'Active Status' &&
                    typeof item.newValue === 'boolean'
                ) {
                    return activeDisplayComponent(item.newValue);
                } else if (
                    item.changeType === 'Subject Line Communication Type' &&
                    typeof item.newValue === 'string'
                ) {
                    return CommTypeMap.get(item.newValue);
                } else {
                    return item.newValue ? item.newValue : '-';
                }
            },
        },
        {
            id: 'operationDate',
            sortingField: 'operationDate',
            header: 'Operation Date',
            cell: (item: LogTableEntry) => item.operationDate,
            sortingComparator: (log1: LogTableEntry, log2: LogTableEntry) =>
                sortLogEntries(log1, log2, sortingAscending),
        },
        {
            id: 'username',
            header: 'User Login',
            width: 150,
            cell: (item: LogTableEntry) => item.userLogin,
        },
        {
            id: 'changeMessage',
            header: 'Change Message',
            cell: (item: LogTableEntry) => item.changeMessage,
        },
    ];
}

const sortLogEntries = (
    log1: LogTableEntry,
    log2: LogTableEntry,
    sortingAscending: boolean
): number => {
    // need custom sorting for table in case of multiple changes happening at the same type
    if (log1.operationDate < log2.operationDate) {
        return -1;
    } else if (log1.operationDate > log2.operationDate) {
        return 1;
    } else {
        // Edge case:
        // We always want the details of a log to appear underneath its clickable row in the table.
        // (This case happens when we have one log that is non-clickable (detail log) and one that is a clickable row.
        // The clickable row does not contain a parentLogId, but the non-clickable row has a parentLogId.)
        // To do that, we need to check whether these rows in the table belong to the same change by checking their sortingId.
        // If they are being sorted in descending order, we must return the inverse of it's normal ordering since the table
        // returns the inverse of the normal sort, but we still want to maintain the change details below the clickable row.
        if (
            !log1.parentLogId &&
            log2.parentLogId &&
            log1.sortingId === log2.sortingId
        ) {
            if (sortingAscending) {
                return -1;
            } else {
                return 1;
            }
        } else if (
            log1.parentLogId &&
            !log2.parentLogId &&
            log1.sortingId === log2.sortingId
        ) {
            if (sortingAscending) {
                return 1;
            } else {
                return -1;
            }
        } else {
            // if two entries in the table are not part of the same change or are both from the same change, but are
            // both non-clickable rows, we can simply sort by their sortingId.
            return log1.sortingId > log2.sortingId ? -1 : 1;
        }
    }
};

export const WC_PAGE_SIZE: number = 10;
export const SL_PAGE_SIZE: number = 10;

// The database has a max size of 2000 bytes and each character can take up to 4 bytes. This is why we set 500 char max.
export const CHANGE_MESSAGE_MAX_LENGTH: number = 500;

// The databse table content_templates Column name has type character varying(200)
export const CONTENT_TEMPLATE_NAME_MAX_LENGTH: number = 200;

export const FV_COLUMN_DEFINITIONS = [
    {
        id: 'feature_name',
        sortingField: 'feature',
        header: 'Feature',
        cell: (item: FeatureValue) => item.feature,
    },
    {
        id: 'key',
        sortingField: 'key',
        header: 'Key',
        cell: (item: FeatureValue) => item.key,
    },
    {
        id: 'value',
        sortingField: 'value',
        header: 'Value',
        cell: (item: FeatureValue) => featureValueCellContent(item),
    },
    {
        id: 'is_active',
        sortingField: 'isActive',
        header: 'Status',
        cell: (item: FeatureValue) => activeDisplayComponent(item.isActive),
    },
    {
        id: 'last_updated',
        sortingField: 'lastUpdatedDate',
        header: 'Last Updated',
        cell: (item: FeatureValue) => item.lastUpdatedDate,
    },
    {
        id: 'last_updated_by',
        sortingField: 'lastUpdatedBy',
        header: 'Last Updated By',
        cell: (item: FeatureValue) => item.lastUpdatedBy,
    },
];

export const DART_COLUMN_DEFINITIONS = [
    {
        id: 'c2c_id',
        header: 'C2C ID',
        cell: (item: DartConfiguration) => item.c2c_id,
    },
    {
        id: 'issue_title',
        header: 'Issue Title',
        cell: (item: DartConfiguration) => item.name,
    },
    {
        id: 'issue_description',
        header: 'Issue Description',
        cell: (item: DartConfiguration) => item.description,
    },
    {
        id: 'creation_date',
        header: 'Creation Date',
        cell: (item: DartConfiguration) => item.create_date,
    },
    {
        id: 'last_updated',
        header: 'Last Updated',
        cell: (item: DartConfiguration) => item.modified_date,
    },
    {
        id: 'last_updated_by',
        header: 'Last Updated By',
        cell: (item: DartConfiguration) => item.modified_user,
    },
    {
        id: 'is_active',
        header: 'Status',
        cell: (item: DartConfiguration) =>
            activeDisplayComponent(item.is_active.toLowerCase() === 'true'),
    },
];

export interface FeatureName {
    displayName: string;
    databaseName: string;
    // TODO: Add "description" field of type string
}

export interface KeyName {
    displayName: string;
    databaseName: string;
    // TODO: Add "type" field of type KeyType
}

/**
 * Database names for features
 */
const FeatureNames: string[] = [
    'StandardizedIssueCode',
    'DefaultQueue',
    'CSChatClippings',
    'EmailAddress',
    'SpellChecker',
    'RSVP',
    'FollowUp',
    'KnowledgeDomain',
    'Composer',
    'EmailChatTranscript',
    'Test Feature June 6',
    'CSSurvey',
    'ReplyToDestination',
    'Test',
];

/**
 * Database names for feature keys
 */
const KeyNames: string[] = [
    'ASTROFeedbackEmailAddress',
    'WizardJumpPointName',
    'HMDChat',
    'EmailTemplateLanguage',
    'FollowUpWorkItemRouting',
    'HMDDisabled',
    'HMDPhone',
    'DefaultOption',
    'EnableHtmlEmail',
    'KnowledgeDomainId',
    'Echo2Chat',
    'Echo1Phone',
    'FromEmailAddress',
    'EmailSubject',
];

/**
 * Set of keys whose values represent emails
 */
export const EmailKeys: Set<string> = new Set([
    'ASTROFeedbackEmailAddress',
    'FromEmailAddress',
    'BCC',
    'ReplyToQueue',
    'From',
]);

export const FeatureMap: Map<string, FeatureName> = new Map();
// TODO: Make this map to actual human readable strings
for (const feature of FeatureNames) {
    const mappedFeature: FeatureName = {
        displayName: feature,
        databaseName: feature,
    };
    FeatureMap.set(feature, mappedFeature);
}

export const KeyMap: Map<string, KeyName> = new Map();
// TODO: Add proper types to mapped KeyName's
for (const key of KeyNames) {
    const mappedKey: KeyName = {
        displayName: key,
        databaseName: key,
    };
    KeyMap.set(key, mappedKey);
}

export const CommTypeMap: Map<string, string> = new Map<string, string>([
    ['C', 'Chat'],
    ['E', 'Email'],
    ['P', 'Phone'],
    ['M', 'Mail'],
    ['F', 'Fax'],
]);

export const CommTypeReverseMap: Map<string, string> = new Map<string, string>([
    ['Chat', 'C'],
    ['Email', 'E'],
    ['Phone', 'P'],
    ['Mail', 'M'],
    ['Fax', 'F'],
]);

export const LOCALES: Set<string> = new Set([
    'en_AU',
    'en_CA',
    'en_GB',
    'en_US',
    'es_ES',
    'es_MX',
    'fr_CA',
    'fr_FR',
    'pt_BR',
    'zh_CN',
]);

export const HMDKeys: Set<string> = new Set<string>([
    'HMDPhone',
    'HMDEmail',
    'HMDChat',
]);

export enum ContentTemplateType {
    Subject = 'Subject',
    Sigblock = 'Sigblock',
}

export const ContentTemplateNameMap: { [key in ContentTemplateType]: string } =
    {
        Subject: 'Subject Line',
        Sigblock: 'Signature Block',
    };

export const hmdKeyToContactTypeMap = (key: string): string => {
    if (key === 'HMDEmail') return 'Email';
    else if (key === 'HMDPhone') return 'Phone';
    else if (key === 'HMDChat') return 'Chat';
    else return key;
};

export enum ProjectNames {
    WorkCategories = 'Work Categories',
}

export const AllSlapshotContracts: Record<ProjectNames, string[]> = {
    [ProjectNames.WorkCategories]: [
        'GetWorkCategories',
        'GetFeatureValuesByWorkCategoryId',
        'GetSigblocksByWorkCategoryId',
        'GetSubjectLines',
        'GetSubjectLinesByWorkCategoryId',
        'GetSurveysBaseInfo',
        'GetWorkCategoriesByFeatureFeatureKeyNameAndValue',
        'GetFeatureNamesAndKeys',
        'UpdateFeatureValue',
        'UpdateContentTemplate',
        'Business:EditWorkCategory',
        'Business:SaveWorkCategory',
        'Business:GetFeatureValueLogsByWorkCategoryIdAggregator',
        'Business:GetWorkCategoryLogsByIdAggregator',
        'Business:AddContentTemplate',
        'Business:AddContentTemplateWorkCategoryAssociation',
        'Business:DeleteContentTemplateWorkCategoryAssociation',
        'Business:GetWorkCategoryContentTemplateAssociationLogsAggregator',
        'Business:GetContentTemplateLogsByIdAggregator',
    ],
};

export const SelfServiceURL =
    'https://w.amazon.com/bin/view/CSPigeonhole/ClientGuide/#HModifyingData';

export const REQUIRED_FIELD = 'This is a required field.';
export const C2C_INFO =
    'A C2C ID should only contain characters (a-z, 0-9, - ) in it.';
