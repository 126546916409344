import { ContentTemplate, GetState, PostState } from '../../../Interfaces';

export const SubjectLinesRequestType = {
    GET_SUBJECT_LINES_REQUEST: 'GET_SUBJECT_LINES_REQUEST',
    GET_SUBJECT_LINES_SUCCESS: 'GET_SUBJECT_LINES_SUCCESS',
    GET_SUBJECT_LINES_FAILURE: 'GET_SUBJECT_LINES_FAILURE',

    ADD_SUBJECT_LINE_REQUEST: 'ADD_SUBJECT_LINE_REQUEST',
    ADD_SUBJECT_LINE_SUCCESS: 'ADD_SUBJECT_LINE_SUCCESS',
    ADD_SUBJECT_LINE_FAILURE: 'ADD_SUBJECT_LINE_FAILURE',
    RESET_SUBJECT_LINE_POST_STATE: 'RESET_SUBJECT_LINE_POST_STATE',
};

/*
 * ACTION TYPES
 */

export interface GetSubjectLinesRequestAction {
    type: typeof SubjectLinesRequestType.GET_SUBJECT_LINES_REQUEST;
}

export interface GetSubjectLinesSuccessAction {
    type: typeof SubjectLinesRequestType.GET_SUBJECT_LINES_SUCCESS;
    data: ContentTemplate[];
}

export interface GetSubjectLinesFailureAction {
    type: typeof SubjectLinesRequestType.GET_SUBJECT_LINES_FAILURE;
}

export interface AddSubjectLineRequestAction {
    type: typeof SubjectLinesRequestType.ADD_SUBJECT_LINE_REQUEST;
}
export interface AddSubjectLineSuccessAction {
    type: typeof SubjectLinesRequestType.ADD_SUBJECT_LINE_SUCCESS;
}

export interface AddSubjectLineFailureAction {
    type: typeof SubjectLinesRequestType.ADD_SUBJECT_LINE_FAILURE;
}

export interface ResetSubjectLinePostStateAction {
    type: typeof SubjectLinesRequestType.RESET_SUBJECT_LINE_POST_STATE;
}

export type SubjectLineActionType =
    | GetSubjectLinesRequestAction
    | GetSubjectLinesSuccessAction
    | GetSubjectLinesFailureAction
    | AddSubjectLineRequestAction
    | AddSubjectLineSuccessAction
    | AddSubjectLineFailureAction
    | ResetSubjectLinePostStateAction;

/*
 * State types for Subject Lines
 */
export interface SubjectLineState {
    subjectLines: ContentTemplate[];
    queryState: GetState;
    postState: PostState;
}
