import React from 'react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Link from '@amzn/awsui-components-react/polaris/link';
import {
    ContentTemplate,
    FeatureValue,
    LogTableEntry,
    ValueType,
    WorkCategory,
} from './Interfaces';
import {
    CHANGE_MESSAGE_MAX_LENGTH,
    EmailKeys,
    KeyMap,
    SelfServiceURL,
} from './Constants';
import {
    History,
    LocationDescriptor,
    LocationDescriptorObject,
    LocationState,
} from 'history';
import queryString from 'query-string';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import { clientConfig } from './api/ClientConfig';
import Box from '@amzn/awsui-components-react/polaris/box';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import Button from '@amzn/awsui-components-react/polaris/button';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

/**
 * @param item the selected work category
 * @returns a `<Link>` component to the work category details page with a given work category ID
 */
export const linkWorkCategory = (item: WorkCategory) => {
    return <Link href={'/react/work-category/' + item.id}>{item.name}</Link>;
};

linkWorkCategory.displayName = 'LinkWorkCategory';

export const linkContentTemplate = (
    item: ContentTemplate,
    workCategoryId: number
) => {
    return (
        <Link
            href={
                '/react/work-category/' +
                workCategoryId +
                '/sigblock/' +
                item.id
            }
        >
            {item.name}
        </Link>
    );
};

export const editContentTemplate = (
    item: ContentTemplate,
    workCategoryId: number
) => {
    return (
        <Link
            href={
                '/react/work-category/' +
                workCategoryId +
                '/sigblock/' +
                item.id
            }
        >
            {'Edit'}
        </Link>
    );
};

export const removeContentTemplate = () => {
    return <Link>{'Remove'}</Link>;
};

linkContentTemplate.displayName = 'LinkContentTemplate';

export const linkSubjectLine = (
    item: ContentTemplate,
    workCategoryId: number
): JSX.Element => {
    return (
        <Link
            href={
                '/react/work-category/' +
                workCategoryId +
                '/subjectline/' +
                item.id
            }
        >
            {item.text}
        </Link>
    );
};

export const exceededChangeMessageLength = (): JSX.Element => {
    return (
        <SpaceBetween size="xxs" direction="horizontal">
            <Icon name="status-negative" size="normal" variant="warning" />
            <Box color="text-status-error">
                Change message must be less than {CHANGE_MESSAGE_MAX_LENGTH}{' '}
                characters.
            </Box>
        </SpaceBetween>
    );
};

export const openChangeDetails = (
    item: LogTableEntry,
    selectedItems: LogTableEntry[],
    addSelectedItems: (id: string) => void,
    removeSelectedItems: (id: string) => void
): JSX.Element => {
    return (
        <div
            onClick={() => {
                const wasOpened = selectedItems.some(
                    (log) => log.logId === item.logId && item.isExpanded
                );
                if (wasOpened) {
                    removeSelectedItems(item.logId);
                } else {
                    addSelectedItems(item.logId);
                }
            }}
        >
            <SpaceBetween size="xs" direction="horizontal">
                {item.isExpanded ? (
                    <Icon name="caret-down" size="normal" variant="normal" />
                ) : (
                    <Icon
                        name="caret-right-filled"
                        size="normal"
                        variant="normal"
                    />
                )}
                <Link>{item.changeType}</Link>
            </SpaceBetween>
        </div>
    );
};

export const EmptyState = ({
    title,
    action,
}: {
    title: string;
    action: JSX.Element;
}): JSX.Element => {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box>{action}</Box>
        </Box>
    );
};

export const ConfirmEditModal = (
    placeholder: string,
    changeMessage: string,
    setChangeMessage: (string) => void,
    updateCall: CancelableEventHandler,
    onDismiss: () => void
): JSX.Element => {
    return (
        <Modal
            onDismiss={onDismiss}
            visible={true}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
                <Box float="right">
                    <Button
                        disabled={
                            !/\S/.test(changeMessage) ||
                            changeMessage.length > CHANGE_MESSAGE_MAX_LENGTH
                        }
                        variant="primary"
                        onClick={updateCall}
                    >
                        Confirm
                    </Button>
                </Box>
            }
            header="Confirm"
        >
            <Box color="text-label">Change Message</Box>
            <Textarea
                onChange={({ detail }) => setChangeMessage(detail.value)}
                value={changeMessage}
                placeholder={placeholder}
            />
            {changeMessage.length > CHANGE_MESSAGE_MAX_LENGTH &&
                exceededChangeMessageLength()}
        </Modal>
    );
};

/**
 * @param isActive boolean value that represents the status of a work category
 * @param positiveMsg string to display if isActive is `true`
 * @param negativeMsg string to display if isActive is `false`
 * @returns An `Icon` component that displays a success or fail message
 */
export const activeDisplayComponent = (
    isActive: boolean,
    positiveMsg: string = 'Active',
    negativeMsg: string = 'Inactive'
): JSX.Element => {
    if (isActive) {
        return <StatusIndicator type="success">{positiveMsg}</StatusIndicator>;
    } else {
        return <StatusIndicator type="error">{negativeMsg}</StatusIndicator>;
    }
};

/**
 * Returns either a string or another component depending on the feature value passed in
 * @param item A feature value
 * @returns Some display component (i.e. `<Icon>`) for specific keys, and just the string otherwise
 */
export const featureValueCellContent = (item: FeatureValue) => {
    let content: ValueType | JSX.Element = item.value;

    if (item.key === KeyMap.get('HMDDisabled').displayName) {
        content = activeDisplayComponent(
            item.value as boolean,
            'Enabled',
            'Disabled'
        );
    }

    return content;
};

/**
 * @param featureKey The feature value key
 * @returns `true` or `false` if the given key refers to an email value
 */
export const isEmailKey = (featureKey: string): boolean => {
    return EmailKeys.has(featureKey);
};

/**
 * @param location a Location object of the current window
 * @returns the qualifier (string) of the phoenix endpoint to use
 */
export const getPhoenixQualifier = (location: Location): string | null => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('pgw')
        ? searchParams.get('pgw')
        : location.hostname.split('.')[0];
};

export const findWorkCategoryWithId = (
    id: number,
    data: WorkCategory[]
): WorkCategory | undefined => {
    // Use the ID to find which work category to display
    return data.find((workCategory) => workCategory.id === id);
};

export const findFeatureValueWithId = (
    id: number,
    data: FeatureValue[]
): FeatureValue | undefined => {
    // Use the ID to find which work category to display
    return data.find((featureValue) => featureValue.id === id);
};

export const findContentTemplateWithId = (
    id: number,
    data: ContentTemplate[]
): ContentTemplate | undefined => {
    return data.find((contentTemplate) => contentTemplate.id === id);
};

// check to see if both cookies are present
export const isLoggedIn = (): boolean => {
    const qualifier = getPhoenixQualifier(window.location);
    const tokenCookieName = clientConfig[qualifier].tokenCookieName;
    const clientStateCookieName = clientConfig[qualifier].clientStateCookieName;
    const allCookies = document.cookie.split(';');
    const hasToken = allCookies.some((cookie) =>
        cookie.includes(tokenCookieName)
    );
    const hasClient = allCookies.some((cookie) =>
        cookie.includes(clientStateCookieName)
    );
    return hasToken && hasClient;
};

export const permissionsNotice = (
    canEdit: boolean,
    permissionsNoticeMessage: string
): JSX.Element => {
    return (
        <Alert visible={!canEdit} type="warning" header="Attention">
            {permissionsNoticeMessage}
            You can request access through the self service link here{' '}
            <Link href={SelfServiceURL}>
                Self Service <Icon name="external" />
            </Link>
            .
        </Alert>
    );
};

export const notLoggedInAlert: JSX.Element = (
    <Alert
        header={`You are not logged in. Please check to see if pop ups are allowed and click the blocked link to log in. 
      Once that is done, please refresh the page.`}
        type="error"
    />
);

// function to get a nicer display for each work category detail change
export const getDisplayedChangeType = (changeType: string): string => {
    switch (changeType) {
        case 'marketplaceId':
            return 'Marketplace ID';
        case 'description':
            return 'Description';
        case 'active':
            return 'Active Status';
        case 'issueCodeGroupId':
            return 'Issue Code Group ID';
        case 'issueSelectionPlanId':
            return 'Issue Selection Plan ID';
        case 'value':
            return 'Value';
        case 'workCategoryKeyName':
            return 'Work Category Key Name';
        case 'workCategoryFeature':
            return 'Work Category Feature';
        case 'workCategoryName':
            return 'Work Category Name';
        // TODO when adding SigBlock audit history, need to conditionally change changeType from 'SubjectLine ...' to "SigBlock ...' based on templateType
        case 'text':
            return 'Subject Line Text';
        case 'name':
            return 'Subject Line Name';
        case 'commTypeCode':
            return 'Subject Line Communication Type';
        case 'locale':
            return 'Subject Line Locale';
        default:
            return changeType;
    }
};

type CreateHistory<O, H> = (options?: O) => History & H;

/**
 * @param createHistory An initial CreateHistory object (a History object
 * from createBrowserHistory())
 * @param queryParameters An array of type string[] with the query parameters
 * you want the history object to preserve throughout navigation
 * @returns A history object preserving the query parameters
 */
export const createPreserveQueryHistory = <O, H>(
    createHistory: CreateHistory<O, H>,
    queryParameters: string[]
): CreateHistory<O, H> => {
    const preserveQueryParameters = (
        history: History,
        preserve: string[],
        location: LocationDescriptorObject
    ): LocationDescriptorObject => {
        const currentQuery = queryString.parse(history.location.search);
        if (currentQuery) {
            const preservedQuery: { [key: string]: unknown } = {};
            for (const p of preserve) {
                const v = currentQuery[p];
                if (v) {
                    preservedQuery[p] = v;
                }
            }
            if (location.search) {
                Object.assign(
                    preservedQuery,
                    queryString.parse(location.search)
                );
            }
            location.search = queryString.stringify(preservedQuery);
        }
        return location;
    };

    const createLocationDescriptorObject = (
        location: LocationDescriptor,
        state?: LocationState
    ): LocationDescriptorObject => {
        return typeof location === 'string'
            ? { pathname: location, state }
            : location;
    };

    return (options?: O) => {
        const history = createHistory(options);
        const oldPush = history.push,
            oldReplace = history.replace;
        history.push = (path: LocationDescriptor, state?: LocationState) =>
            oldPush.apply(history, [
                preserveQueryParameters(
                    history,
                    queryParameters,
                    createLocationDescriptorObject(path, state)
                ),
            ]);
        history.replace = (path: LocationDescriptor, state?: LocationState) =>
            oldReplace.apply(history, [
                preserveQueryParameters(
                    history,
                    queryParameters,
                    createLocationDescriptorObject(path, state)
                ),
            ]);
        return history;
    };
};
