// All typescript interfaces used will be defined here

export interface WorkCategory {
    id: number;
    name: string;
    marketplaceId: number;
    description: string;
    isActive: boolean;
    lastUpdatedDate: string;
    lastUpdatedBy: string;
    creationDate: string;
    // Optional values
    createdBy?: string;
    issueCodeGroupId?: number;
    issueSelectionPlanId?: number;
}

export interface NewWorkCategory {
    name: string;
    active: boolean;
    description: string;
    marketPlaceID: number;
    fromEmail: string;
    replyToEmail: string;
    issueCodeGroupID: string;
    issueSelectionPlanID: string;
}

export interface ContentTemplate {
    id: number;
    name: string;
    templateType: string; // com.amazon.CSContactCommonTypes.ContentTemplateType
    commTypeCode: string; // com.amazon.CSContactCommonTypes.CommType
    text: string;
    locale: string;
    isActive: boolean;
    lastEditedDate: string;
    lastEditedBy: string;
    creationDate: string;
    createdBy: string;
    associatedWorkCategories?: WorkCategory[];
}

export interface NewContentTemplate {
    name: string;
    templateType: string; // com.amazon.CSContactCommonTypes.ContentTemplateType
    commTypeCode: string; // com.amazon.CSContactCommonTypes.CommType
    text: string;
    locale: string;
    isActive: boolean;
    lastEditedBy: string;
    associatedWorkCategoryIds: string;
}

export interface WorkCategoryLog {
    logId?: string;
    parentLogId?: string;
    changeType?: string;
    id: number;
    name: string;
    marketplaceId: number;
    description: string;
    isActive: boolean;
    issueCodeGroupId: number;
    issueSelectionPlanId: number;
    previousValue: string | boolean | number;
    newValue: string | boolean | number;
    operationDate?: string;
    userLogin?: string;
    changeMessage?: string;
    isExpanded: boolean;
    expandable?: boolean;
}

export interface FeatureValueLog {
    logId?: string;
    parentLogId?: string;
    changeType?: string;
    active: boolean;
    value: string;
    workCategoryFeatureValueId: number;
    workCategoryId: string;
    workCategoryFeature: string;
    workCategoryKeyName: string;
    operationDate: string;
    userLogin?: string;
    changeMessage?: string;
    isExpanded?: boolean;
    expandable?: boolean;
}

export interface WorkCategoryContentTemplateAssociationLog {
    logId?: string;
    parentLogId?: string;
    changeType?: string;
    isExpanded?: boolean;
    expandable?: boolean;
    userLogin?: string;
    operation: string;
    contentTemplateId: string;
    contentTemplateType: string;
    operationDate: string;
    workCategoryId: string;
    contentTemplateName: string;
    creationDate: string;
    changeMessage?: string;
}

export interface ContentTemplateLog {
    logId?: string;
    parentLogId?: string;
    id: string;
    userLogin?: string;
    templateType: string;
    changeMessage: string;
    commTypeCode: string;
    text: string;
    name: string;
    operationDate: string;
    active: boolean;
    locale: string;
    changeType: string;
    isExpanded?: boolean;
    expandable?: boolean;
}

export interface LogTableEntry {
    logId?: string;
    parentLogId?: string;
    changeType?: string;
    previousValue: string | boolean | number;
    newValue: string | boolean | number;
    operationDate: string;
    userLogin?: string;
    changeMessage?: string;
    isExpanded?: boolean;
    sortingId?: string;
    expandable?: boolean;
}

export interface FeatureKey {
    featureKey: string;
    featureName: string;
    isActive: boolean;
    creationDate: string;
    createdBy: string;
    lastUpdatedDate: string;
    lastUpdatedBy: string;
}

export interface Feature {
    featureName: string;
    isActive: boolean;
    creationDate: string;
    createdBy: string;
    lastUpdatedDate: string;
    lastUpdatedBy: string;
    featureKeys: FeatureKey[];
}

export type ValueType = string | boolean;

export interface FeatureValue {
    id: number;
    feature: string;
    key: string;
    value: ValueType;
    sortOrder: number;
    isActive: boolean;
    creationDate: string;
    createdBy: string;
    lastUpdatedDate: string;
    lastUpdatedBy: string;
}

export interface FeatureValueProps {
    workCategoryId: number;
    canEdit: boolean;
}

/**
 * Enum to represent the current state of the GET request.
 */
export enum GetState {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

/**
 * Enum to represent the current state of the POST request
 */
export enum PostState {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export interface SlapshotContract {
    projectName: string;
    contractId: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    fulfillmentArguments: Object;
}

export interface Survey {
    surveyId: string;
    surveyName: string;
    language: string;
    description: string;
    surveyType: string;
    pollInvitationCategory: string;
    surveyOwner: string;
}

export interface DartConfiguration {
    work_category_id: string;
    marketplace_id: string;
    c2c_id: string;
    name: string;
    description: string;
    create_date: string;
    modified_date: string;
    modified_user: string;
    is_active: string;
    old_name?: string;
}

// Interface for the returned objects of GetWorkCategoriesByFeatureValue call which are less detailed and have different
// field names than WorkCategory
export interface WorkCategoryShort {
    workCategoryID: string;
    marketplaceID: string;
    issueSelectionPlanID: string;
    issueCodeGroupID: string;
    name: string;
    description: string;
    isActive: boolean;
}
