import {
    GetFeatureValueLogsSuccessAction,
    GetWorkCategoryContentTemplateAssociationLogsSuccessAction,
    GetWorkCategoryLogsSuccessAction,
    Types,
    WorkCategoryLogsActionType,
    WorkCategoryLogState,
} from './AuditHistory.types';
import { GetState } from '../../../../Interfaces';

const initialState: WorkCategoryLogState = {
    workCategoryAudit: {
        getState: GetState.INITIAL,
        workCategoryLogs: [],
    },
    featureValueAudit: {
        getState: GetState.INITIAL,
        featureValueLogs: [],
    },
    workCategoryContentTemplateAssociationAudit: {
        getState: GetState.INITIAL,
        associationLogs: [],
    },
};

export const AuditHistoryReducer = (
    prevState: WorkCategoryLogState = initialState,
    action: WorkCategoryLogsActionType
) => {
    switch (action.type) {
        case Types.GET_WORKCATEGORYLOGS_REQUEST:
            return {
                ...prevState,
                workCategoryAudit: {
                    ...prevState.workCategoryAudit,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_WORKCATEGORYLOGS_SUCCESS:
            return {
                ...prevState,
                workCategoryAudit: {
                    ...prevState.workCategoryAudit,
                    workCategoryLogs: (
                        action as GetWorkCategoryLogsSuccessAction
                    ).data,
                    getState: GetState.SUCCESS,
                },
            };

        case Types.GET_WORKCATEGORYLOGS_FAILURE:
            return {
                ...prevState,
                workCategoryAudit: {
                    ...prevState.workCategoryAudit,
                    getState: GetState.FAILURE,
                },
            };

        case Types.GET_FEATUREVALUELOGS_REQUEST:
            return {
                ...prevState,
                featureValueAudit: {
                    ...prevState.featureValueAudit,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_FEATUREVALUELOGS_SUCCESS:
            return {
                ...prevState,
                featureValueAudit: {
                    ...prevState.featureValueAudit,
                    featureValueLogs: (
                        action as GetFeatureValueLogsSuccessAction
                    ).data,
                    getState: GetState.SUCCESS,
                },
            };

        case Types.GET_FEATUREVALUELOGS_FAILURE:
            return {
                ...prevState,
                featureValueAudit: {
                    ...prevState.featureValueAudit,
                    getState: GetState.FAILURE,
                },
            };

        case Types.GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_REQUEST:
            return {
                ...prevState,
                workCategoryContentTemplateAssociationAudit: {
                    ...prevState.workCategoryContentTemplateAssociationAudit,
                    getState: GetState.LOADING,
                },
            };

        case Types.GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_SUCCESS:
            return {
                ...prevState,
                workCategoryContentTemplateAssociationAudit: {
                    ...prevState.workCategoryContentTemplateAssociationAudit,
                    associationLogs: (
                        action as GetWorkCategoryContentTemplateAssociationLogsSuccessAction
                    ).data,
                    getState: GetState.SUCCESS,
                },
            };

        case Types.GET_WORKCATEGORYCONTENTTEMPLATEASSOCIATIONLOGS_FAILURE:
            return {
                ...prevState,
                workCategoryContentTemplateAssociationAudit: {
                    ...prevState.workCategoryContentTemplateAssociationAudit,
                    getState: GetState.FAILURE,
                },
            };

        default:
            return {
                workCategoryAudit: {
                    getState: GetState.INITIAL,
                    workCategoryLogs: [],
                },
                featureValueAudit: {
                    getState: GetState.INITIAL,
                    featureValueLogs: [],
                },
                workCategoryContentTemplateAssociationAudit: {
                    getState: GetState.INITIAL,
                    associationLogs: [],
                },
            };
    }
};

export default AuditHistoryReducer;
