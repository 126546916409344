import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './containers/Root';
import { store } from './containers/redux/store';

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Root store={store} />
        </React.StrictMode>
    );
} else {
    throw new Error(
        "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file."
    );
}
