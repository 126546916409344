import {
    Feature,
    FeatureValue,
    GetState,
    PostState,
    ValueType,
} from '../../../../Interfaces';

export const Types = {
    GET_FEATURE_VALUES_REQUEST: 'GET_FEATURE_VALUES_REQUEST',
    GET_FEATURE_VALUES_SUCCESS: 'GET_FEATURE_VALUES_SUCCESS',
    GET_FEATURE_VALUES_FAILURE: 'GET_FEATURE_VALUES_FAILURE',

    SET_FEATURE_VALUE_IS_ACTIVE: 'SET_FEATURE_VALUE_IS_ACTIVE',
    SET_FEATURE_VALUE_VALUE: 'SET_FEATURE_VALUE_VALUE',
    UPDATE_FEATURE_VALUE_REQUEST: 'UPDATE_FEATURE_VALUE_REQUEST',
    UPDATE_FEATURE_VALUE_SUCCESS: 'UPDATE_FEATURE_VALUE_SUCCESS',
    UPDATE_FEATURE_VALUE_FAILURE: 'UPDATE_FEATURE_VALUE_FAILURE',

    RESET_FEATURE_VALUE_POST_STATE: 'RESET_FEATURE_VALUE_POST_STATE',
    RESET_FEATURE_VALUES_STATE: 'RESET_FEATURE_VALUES_STATE',

    GET_FEATURE_NAMES_AND_KEYS_REQUEST: 'GET_FEATURE_NAMES_AND_KEYS_REQUEST',
    GET_FEATURE_NAMES_AND_KEYS_SUCCESS: 'GET_FEATURE_NAMES_AND_KEYS_SUCCESS',
    GET_FEATURE_NAMES_AND_KEYS_FAILURE: 'GET_FEATURE_NAMES_AND_KEYS_FAILURE',
    RESET_FEATURE_NAMES_AND_KEYS_STATE: 'RESET_FEATURE_NAMES_AND_KEYS_STATE',
};

/*
 * ACTION TYPES
 */
export interface GetFeatureValuesRequestAction {
    type: typeof Types.GET_FEATURE_VALUES_REQUEST;
}

export interface GetFeatureValuesSuccessAction {
    type: typeof Types.GET_FEATURE_VALUES_SUCCESS;
    data: FeatureValue[];
}

export interface GetFeatureValuesFailureAction {
    type: typeof Types.GET_FEATURE_VALUES_FAILURE;
}

export interface SetFeatureValueIsActiveAction {
    type: typeof Types.SET_FEATURE_VALUE_IS_ACTIVE;
    featureValueId: number;
    isActive: boolean;
}

export interface SetFeatureValueValueAction {
    type: typeof Types.SET_FEATURE_VALUE_VALUE;
    featureValueId: number;
    value: ValueType;
}

export interface UpdateFeatureValueRequestAction {
    type: typeof Types.UPDATE_FEATURE_VALUE_REQUEST;
}

export interface UpdateFeatureValueSuccessAction {
    type: typeof Types.UPDATE_FEATURE_VALUE_SUCCESS;
}

export interface UpdateFeatureValueFailureAction {
    type: typeof Types.UPDATE_FEATURE_VALUE_FAILURE;
}

export interface GetFeatureNamesAndKeysRequestAction {
    type: typeof Types.GET_FEATURE_NAMES_AND_KEYS_REQUEST;
}

export interface GetFeatureNamesAndKeysSuccessAction {
    type: typeof Types.GET_FEATURE_NAMES_AND_KEYS_SUCCESS;
    data: Feature[];
}

export interface GetFeatureNamesAndKeysFailureAction {
    type: typeof Types.GET_FEATURE_NAMES_AND_KEYS_FAILURE;
}

export interface ResetFeatureNamesAndKeysStateAction {
    type: typeof Types.RESET_FEATURE_NAMES_AND_KEYS_STATE;
}

export type FeatureValuesActionType =
    | GetFeatureValuesRequestAction
    | GetFeatureValuesSuccessAction
    | GetFeatureValuesFailureAction
    | SetFeatureValueIsActiveAction
    | SetFeatureValueValueAction
    | UpdateFeatureValueRequestAction
    | UpdateFeatureValueSuccessAction
    | UpdateFeatureValueFailureAction
    | GetFeatureNamesAndKeysRequestAction
    | GetFeatureNamesAndKeysSuccessAction
    | GetFeatureNamesAndKeysFailureAction
    | ResetFeatureNamesAndKeysStateAction;

/*
 *State types for Work Categories
 */
export interface FeatureValueState {
    featureValues: FeatureValue[];
    getState: GetState;
    postState: PostState;
    featureNamesAndKeys: {
        getState: GetState;
        features: Feature[];
    };
}
