import Loadable from 'react-loadable';
import Loading from './LoadingComponent';

const MyLoadable = (opts) =>
    Loadable(
        Object.assign(
            {
                loading: Loading,
                delay: 1000,
                timeout: 5000,
            },
            opts
        )
    );

export default MyLoadable;
