import { GetState, Survey, WorkCategoryShort } from '../../../../../Interfaces';

export const Types = {
    GET_SURVEYS_BASE_INFO_REQUEST: 'GET_SURVEYS_BASE_INFO_REQUEST',
    GET_SURVEYS_BASE_INFO_SUCCESS: 'GET_SURVEYS_BASE_INFO_SUCCESS',
    GET_SURVEYS_BASE_INFO_FAILURE: 'GET_SURVEYS_BASE_INFO_FAILURE',

    GET_WORK_CATEGORIES_FOR_HMD_REQUEST: 'GET_WORK_CATEGORIES_FOR_HMD_REQUEST',
    GET_WORK_CATEGORIES_FOR_HMD_SUCCESS: 'GET_WORK_CATEGORIES_FOR_HMD_SUCCESS',
    GET_WORK_CATEGORIES_FOR_HMD_FAILURE: 'GET_WORK_CATEGORIES_FOR_HMD_FAILURE',

    RESET_ASSOCIATED_WORK_CATEGORIES_STATE:
        'RESET_ASSOCIATED_WORK_CATEGORIES_STATE',
};

export interface SurveyState {
    surveys: Survey[];
    getState: GetState;
    associatedWorkCategories: {
        getState: GetState;
        workCategories: WorkCategoryShort[];
    };
}

export interface GetSurveysBaseInfoRequestAction {
    type: typeof Types.GET_SURVEYS_BASE_INFO_REQUEST;
}

export interface GetSurveysBaseInfoSuccessAction {
    type: typeof Types.GET_SURVEYS_BASE_INFO_SUCCESS;
    payload: Survey[];
}

export interface GetSurveysBaseInfoFailureAction {
    type: typeof Types.GET_SURVEYS_BASE_INFO_FAILURE;
}

export interface GetWorkCategoriesForHMDRequestAction {
    type: typeof Types.GET_WORK_CATEGORIES_FOR_HMD_REQUEST;
}

export interface GetWorkCategoriesForHMDSuccessAction {
    type: typeof Types.GET_WORK_CATEGORIES_FOR_HMD_SUCCESS;
    payload: WorkCategoryShort[];
}

export interface GetWorkCategoriesForHMDFailureAction {
    type: typeof Types.GET_WORK_CATEGORIES_FOR_HMD_FAILURE;
}

export interface ResetAssociatedWorkCategoriesStateAction {
    type: typeof Types.RESET_ASSOCIATED_WORK_CATEGORIES_STATE;
}

export type SurveyActionType =
    | GetSurveysBaseInfoRequestAction
    | GetSurveysBaseInfoSuccessAction
    | GetSurveysBaseInfoFailureAction
    | GetWorkCategoriesForHMDRequestAction
    | GetWorkCategoriesForHMDSuccessAction
    | GetWorkCategoriesForHMDFailureAction
    | ResetAssociatedWorkCategoriesStateAction;
