import {
    ContentTemplate,
    ContentTemplateLog,
    GetState,
    PostState,
} from '../../../../Interfaces';

export const Types = {
    GET_SIGBLOCKS_REQUEST: 'GET_SIGBLOCKS_REQUEST',
    GET_SIGBLOCKS_SUCCESS: 'GET_SIGBLOCKS_SUCCESS',
    GET_SIGBLOCKS_FAILURE: 'GET_SIGBLOCKS_FAILURE',

    UPDATE_SIGBLOCK_REQUEST: 'UPDATE_SIGBLOCK_REQUEST',
    UPDATE_SIGBLOCK_SUCCESS: 'UPDATE_SIGBLOCK_SUCCESS',
    UPDATE_SIGBLOCK_FAILURE: 'UPDATE_SIGBLOCK_FAILURE',

    ADD_SIGBLOCK_REQUEST: 'ADD_SIGBLOCK_REQUEST',
    ADD_SIGBLOCK_SUCCESS: 'ADD_SIGBLOCK_SUCCESS',
    ADD_SIGBLOCK_FAILURE: 'ADD_SIGBLOCK_FAILURE',

    REMOVE_SIGBLOCK_REQUEST: 'REMOVE_SIGBLOCK_REQUEST',
    REMOVE_SIGBLOCK_SUCCESS: 'REMOVE_SIGBLOCK_SUCCESS',
    REMOVE_SIGBLOCK_FAILURE: 'REMOVE_SIGBLOCK_FAILURE',

    RESET_SIGBLOCK_POST_STATE: 'RESET_SIGBLOCK_POST_STATE',

    GET_SUBJECTLINES_BY_WORKCATEGORY_ID_REQUEST:
        'GET_SUBJECTLINES_BY_WORKCATEGORY_ID_REQUEST',
    GET_SUBJECTLINES_BY_WORKCATEGORY_ID_SUCCESS:
        'GET_SUBJECTLINES_BY_WORKCATEGORY_ID_SUCCESS',
    GET_SUBJECTLINES_BY_WORKCATEGORY_ID_FAILURE:
        'GET_SUBJECTLINES_BY_WORKCATEGORY_ID_FAILURE',

    UPDATE_SUBJECTLINE_REQUEST: 'UPDATE_SUBJECTLINE_REQUEST',
    UPDATE_SUBJECTLINE_SUCCESS: 'UPDATE_SUBJECTLINE_SUCCESS',
    UPDATE_SUBJECTLINE_FAILURE: 'UPDATE_SUBJECTLINE_FAILURE',

    RESET_SUBJECTLINE_POST_STATE: 'RESET_SUBJECTLINE_POST_STATE',

    RESET_CONTENT_TEMPLATE_STATE: 'RESET_CONTENT_TEMPLATE_STATE',

    ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST:
        'ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST',
    ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS:
        'ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS',
    ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE:
        'ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE',

    REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST:
        'REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST',
    REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS:
        'REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS',
    REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE:
        'REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE',

    RESET_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_POST_STATE:
        'RESET_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_POST_STATE',

    GET_CONTENT_TEMPLATE_LOGS_REQUEST: 'GET_CONTENT_TEMPLATE_LOGS_REQUEST',
    GET_CONTENT_TEMPLATE_LOGS_SUCCESS: 'GET_CONTENT_TEMPLATE_LOGS_SUCCESS',
    GET_CONTENT_TEMPLATE_LOGS_FAILURE: 'GET_CONTENT_TEMPLATE_LOGS_FAILURE',
};

/*
 * ACTION TYPES
 */
export interface GetSigblocksRequestAction {
    type: typeof Types.GET_SIGBLOCKS_REQUEST;
}

export interface GetSigblocksSuccessAction {
    type: typeof Types.GET_SIGBLOCKS_SUCCESS;
    data: ContentTemplate[];
}

export interface GetSigblocksFailureAction {
    type: typeof Types.GET_SIGBLOCKS_FAILURE;
}

export interface UpdateSigblockRequestAction {
    type: typeof Types.UPDATE_SIGBLOCK_REQUEST;
}

export interface UpdateSigblockSuccessAction {
    type: typeof Types.UPDATE_SIGBLOCK_SUCCESS;
}

export interface UpdateSigblockFailureAction {
    type: typeof Types.UPDATE_SIGBLOCK_FAILURE;
}

export interface GetSubjectLinesRequestAction {
    type: typeof Types.GET_SIGBLOCKS_REQUEST;
}

export interface GetSubjectLinesSuccessAction {
    type: typeof Types.GET_SUBJECTLINES_BY_WORKCATEGORY_ID_SUCCESS;
    payload: ContentTemplate[];
}

export interface GetSubjectLinesFailureAction {
    type: typeof Types.GET_SUBJECTLINES_BY_WORKCATEGORY_ID_FAILURE;
}

export interface UpdateSubjectLineRequestAction {
    type: typeof Types.UPDATE_SUBJECTLINE_REQUEST;
}

export interface UpdateSubjectLineSuccessAction {
    type: typeof Types.UPDATE_SUBJECTLINE_SUCCESS;
}

export interface UpdateSubjectLineFailureAction {
    type: typeof Types.UPDATE_SUBJECTLINE_FAILURE;
}

export interface AddSubjectLineWorkCategoryAssociationRequest {
    type: typeof Types.ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST;
}

export interface AddSubjectLineWorkCategoryAssociationSuccess {
    type: typeof Types.ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS;
}

export interface AddSubjectLineWorkCategoryAssociationFailure {
    type: typeof Types.ADD_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE;
}

export interface RemoveSubjectLineWorkCategoryAssociationRequest {
    type: typeof Types.REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_REQUEST;
}

export interface RemoveSubjectLineWorkCategoryAssociationSuccess {
    type: typeof Types.REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_SUCCESS;
}

export interface RemoveSubjectLineWorkCategoryAssociationFailure {
    type: typeof Types.REMOVE_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_FAILURE;
}

export interface ResetSubjectLineWorkCategoryAssociationRequest {
    type: typeof Types.RESET_SUBJECT_LINE_WORK_CATEGORY_ASSOCIATION_POST_STATE;
}

export interface GetContentTemplateLogsRequestAction {
    type: typeof Types.GET_CONTENT_TEMPLATE_LOGS_REQUEST;
}
export interface GetContentTemplateLogsSuccessAction {
    type: typeof Types.GET_CONTENT_TEMPLATE_LOGS_SUCCESS;
    data: ContentTemplateLog[];
}
export interface GetContentTemplateLogsFailureAction {
    type: typeof Types.GET_CONTENT_TEMPLATE_LOGS_FAILURE;
}

export type ContentTemplatesActionType =
    | GetSigblocksRequestAction
    | GetSigblocksSuccessAction
    | GetSigblocksFailureAction
    | UpdateSigblockRequestAction
    | UpdateSigblockSuccessAction
    | UpdateSigblockFailureAction
    | GetSubjectLinesRequestAction
    | GetSubjectLinesSuccessAction
    | GetSubjectLinesFailureAction
    | UpdateSubjectLineRequestAction
    | UpdateSubjectLineSuccessAction
    | UpdateSubjectLineFailureAction
    | AddSubjectLineWorkCategoryAssociationRequest
    | AddSubjectLineWorkCategoryAssociationSuccess
    | AddSubjectLineWorkCategoryAssociationFailure
    | RemoveSubjectLineWorkCategoryAssociationRequest
    | RemoveSubjectLineWorkCategoryAssociationSuccess
    | RemoveSubjectLineWorkCategoryAssociationFailure
    | ResetSubjectLineWorkCategoryAssociationRequest
    | GetContentTemplateLogsRequestAction
    | GetContentTemplateLogsSuccessAction
    | GetContentTemplateLogsFailureAction;

/*
 *State types for Work Categories
 */
export interface ContentTemplateState {
    sigblocks: {
        sigblocks: ContentTemplate[];
        getState: GetState;
        postState: PostState;
    };
    subjectLines: {
        subjectLines: ContentTemplate[];
        getState: GetState;
        postState: PostState;
        subjectLineWorkCategoryAssociation: {
            postState: PostState;
        };
    };
    contentTemplateAudit: {
        contentTemplateLogs: ContentTemplateLog[];
        getState: GetState;
    };
}
