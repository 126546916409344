import React, { Component } from 'react';
import Routes from '../routes';
import 'font-awesome/css/font-awesome.min.css';
import SideNav from './SideNav.component';
import '@amzn/awsui-global-styles/polaris.css';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Router } from 'react-router-dom';
import { createPreserveQueryHistory } from '../Utilities.tsx';
import { createBrowserHistory } from 'history';
import { AllSlapshotContracts } from '../Constants';
import { getPhoenixAPI } from '../api/Phoenix';

export const PermissionsContext = React.createContext(AllSlapshotContracts);

class App extends Component {
    historyWithQuery = createPreserveQueryHistory(createBrowserHistory, [
        'pgw',
    ])();
    // Wrap the contracts in the state so setState we can call setState to force a re-render
    state = {
        slapshotContractList: AllSlapshotContracts,
    };

    componentDidMount() {
        getPhoenixAPI()
            .filterOperations(this.state.slapshotContractList)
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error('Failed to check user permissions');
            })
            .then((parsedResponse) => {
                const newState = { ...this.state };
                newState.slapshotContractList = parsedResponse;
                this.setState(newState);
            })
            .catch((err) => {
                console.log(err);
                // If the call fails, revoke all permissions instead of keeping the list unfiltered
                const newState = { ...this.state };
                newState.slapshotContractList = { 'Work Categories': [] };
                this.setState(newState);
            });
    }

    render() {
        return (
            <PermissionsContext.Provider
                value={this.state.slapshotContractList}
            >
                <div className="awsui">
                    <Router history={this.historyWithQuery}>
                        <AppLayout
                            content={Routes}
                            headerSelector="#h"
                            navigation={<SideNav />}
                            toolsHide={true}
                        />
                    </Router>
                </div>
            </PermissionsContext.Provider>
        );
    }
}

export default App;
