import {
    DartConfiguration,
    GetState,
    PostState,
} from '../../../../../Interfaces';

export const Types = {
    GET_DART_CONFIGURATIONS_REQUEST: 'GET_DART_CONFIGURATIONS_REQUEST',
    GET_DART_CONFIGURATIONS_SUCCESS: 'GET_DART_CONFIGURATIONS_SUCCESS',
    GET_DART_CONFIGURATIONS_FAILURE: 'GET_DART_CONFIGURATIONS_FAILURE',
    ADD_DART_CONFIGURATIONS_REQUEST: 'ADD_DART_CONFIGURATIONS_REQUEST',
    ADD_DART_CONFIGURATIONS_SUCCESS: 'ADD_DART_CONFIGURATIONS_SUCCESS',
    ADD_DART_CONFIGURATIONS_FAILURE: 'ADD_DART_CONFIGURATIONS_FAILURE',
    UPDATE_DART_CONFIGURATIONS_REQUEST: 'UPDATE_DART_CONFIGURATIONS_REQUEST',
    UPDATE_DART_CONFIGURATIONS_SUCCESS: 'UPDATE_DART_CONFIGURATIONS_SUCCESS',
    UPDATE_DART_CONFIGURATIONS_FAILURE: 'UPDATE_DART_CONFIGURATIONS_FAILURE',
};

export interface DartState {
    dartConfigs: DartConfiguration[];
    getState: GetState;
    postState: PostState;
}

export interface GetDartConfigurationsRequestAction {
    type: typeof Types.GET_DART_CONFIGURATIONS_REQUEST;
}

export interface GetDartConfigurationsSuccessAction {
    type: typeof Types.GET_DART_CONFIGURATIONS_SUCCESS;
    payload: DartConfiguration[];
}

export interface GetDartConfigurationsFailureAction {
    type: typeof Types.GET_DART_CONFIGURATIONS_FAILURE;
}

export interface AddDartConfigurationRequestAction {
    type: typeof Types.ADD_DART_CONFIGURATIONS_REQUEST;
}

export interface AddDartConfigurationSuccessAction {
    type: typeof Types.ADD_DART_CONFIGURATIONS_SUCCESS;
    payload: DartConfiguration[];
}

export interface AddDartConfigurationFailureAction {
    type: typeof Types.ADD_DART_CONFIGURATIONS_FAILURE;
}

export interface UpdateDartConfigurationRequestAction {
    type: typeof Types.UPDATE_DART_CONFIGURATIONS_REQUEST;
}

export interface UpdateDartConfigurationSuccessAction {
    type: typeof Types.UPDATE_DART_CONFIGURATIONS_SUCCESS;
    payload: DartConfiguration[];
}

export interface UpdateDartConfigurationFailureAction {
    type: typeof Types.UPDATE_DART_CONFIGURATIONS_FAILURE;
}

export type DartActionType =
    | GetDartConfigurationsRequestAction
    | GetDartConfigurationsSuccessAction
    | GetDartConfigurationsFailureAction
    | AddDartConfigurationRequestAction
    | AddDartConfigurationSuccessAction
    | AddDartConfigurationFailureAction
    | UpdateDartConfigurationRequestAction
    | UpdateDartConfigurationSuccessAction
    | UpdateDartConfigurationFailureAction;
