import { Route, Switch } from 'react-router-dom';
import MyLoadable from './components/LoadableComponent';
import { AuthReceiver } from '@amzn/csphoenix-react-client';
import React from 'react';

const LoadableWorkCategoryComponent = MyLoadable({
    loader: async () =>
        import('./containers/WorkCategory/WorkCategoryTable/WorkCategory'),
});

const LoadableWorkCategoryDetailsPageComponent = MyLoadable({
    loader: async () =>
        import('./containers/WorkCategoryDetails/WorkCategoryDetailsPage'),
});

const LoadableSubjectLinesComponent = MyLoadable({
    loader: async () =>
        import('./containers/SubjectLines/SubjectLinesTable/SubjectLines'),
});

const LoadableContentTemplateForm = MyLoadable({
    loader: async () =>
        import(
            './containers/WorkCategoryDetails/ContentTemplates/SigblockUpdatePage'
        ),
});

const LoadableSubjectLineForm = MyLoadable({
    loader: async () =>
        import(
            './containers/WorkCategoryDetails/ContentTemplates/SubjectLineUpdatePage'
        ),
});

const LoadableHMDDetail = MyLoadable({
    loader: async () =>
        import(
            './containers/WorkCategoryDetails/FeatureValues/HMD/HMDDetailPage'
        ),
});

const LoadableAudit = MyLoadable({
    loader: async () =>
        import('./containers/WorkCategoryDetails/AuditHistory/AuditTable'),
});

export default (
    <Switch>
        <Route
            exact
            path="/react/work-category"
            render={() => <LoadableWorkCategoryComponent />}
        />

        <Route
            exact
            path="/react/subject-lines"
            render={() => <LoadableSubjectLinesComponent />}
        />

        <Route
            exact
            path="/react/work-category/:id"
            render={() => <LoadableWorkCategoryDetailsPageComponent />}
        />

        <Route
            exact
            path="/react/work-category/:workCategoryId/sigblock/:contentTemplateId"
            render={() => <LoadableContentTemplateForm />}
        />

        <Route path="/:locale/auth/:cookieName" component={AuthReceiver} />

        <Route
            exact
            path="/react/work-category/:workCategoryId/subjectline/:contentTemplateId"
            render={() => <LoadableSubjectLineForm />}
        />

        <Route
            exact
            path="/react/work-category/:workCategoryId/hmd/:hmdId"
            render={() => <LoadableHMDDetail />}
        />

        <Route
            exact
            path="/react/work-category/:workCategoryId/audit"
            render={() => <LoadableAudit />}
        />
    </Switch>
);
